/** @format */

div .cardTitle {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}

.chipCard span {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  text-transform: capitalize;
}

.chipCard .scopeChip {
  border-radius: 8px;
}
