/** @format */

.title {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

button[type='submit'] {
  color: #fff;
}
.assetFormContainer {
  border-top: 1px solid rgb(129, 149, 156, 0.3);
  margin-top: 32px;
  padding-top: 32px;
}
form .enterMonthly {
  color: #577077;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 24px;
  column-gap: 16px;
  border-radius: 8px;
  background: #f6f7fb;
  box-shadow: 0px 2px 10px 0px #d9dbe4;
  padding: 20px;
}

@media only screen and (max-width: 1200px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 900px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 750px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
