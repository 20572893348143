/** @format */
.MuiStack-root .css-1arvn2p-MuiStack-root {
  max-width: 800px;
  margin: 0 auto;
}

.stepper-form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 50%;
}

/* *****************STEPPER***************************** */
.css-t8senq-MuiStepper-root {
  height: 24px;
}

.css-117w1su-MuiStepIcon-text {
  width: 24px;
  height: 24px;
}

.MuiStepper-horizontal {
  margin-top: 48px;
}

.MuiYearCalendar-root button[aria-checked="true"] {
  color: #fff;
}

.MuiDayCalendar-monthContainer button[aria-selected="true"] {
  color: #fff;
}
/* ****************************  STEP 2 **************************** */
.action-plan-headline {
  display: inline-block;
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "roboto" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
}

.add-action-plan {
  color: #4f5367;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  border-radius: 100px;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 16px;
  margin-bottom: 32px;
}

.MuiInputBase-input
  .MuiOutlinedInput-input
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #4f5367;
  font-feature-settings: "clig" off, "liga" off;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.stepper-form .MuiStepIcon-text {
  fill: rgba(255, 255, 255);
  font-size: 12px;
  font-family: inherit;
}

.MuiBox-root .action-plan-headline {
  margin: 32px 0;
}

.step--2-headline .action-plan-headline {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
  display: inline-block;
  margin-bottom: 32px;
  margin-top: 0;
}
/* ******************************* STEP 3 ******************************* */

.step-3-headline {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.MuiStack-root .list-heading {
  display: inline-block;
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  margin-top: 16px;
}
.action-list-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}
.action-list-item {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-left: 20px;
}
.action-sub-heading,
.no-action {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
}

.MuiStack-root .status {
  color: #1e7723;
}
.no-action {
  color: #ff8080;
}

.hot-toast-container {
  font-family: "roboto";
}

.hot-toast {
  font-family: "roboto";
}
