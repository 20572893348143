/** @format */

.sidebar {
  box-shadow: 0px 1px 31px 0px rgba(15, 51, 61, 0.12);
  height: 100%;
  width: 260px;
  position: fixed;
  z-index: 1200;
}

.esg-logo {
  margin: 24px 67px 32px 16px;
  width: 177px;
  height: 50px;
}

.list-item span {
  color: #82959c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.active span {
  color: #ffffff;
}
