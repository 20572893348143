/** @format */

.modal-card {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 400;
  box-shadow: 24px;
  padding: 24px;
}

.edit-box-modal .css-gmwslw-MuiStack-root {
  font-family: "roboto", sans-serif;
}

.edit-box-modal .css-6prj3h-MuiStack-root {
  padding-top: 16px;
}

.edit-box-modal {
  padding-top: 16px !important;
}

.css-6prj3h-MuiStack-root .btn--cancel {
  background-color: #e8ebf2;
  color: #577077;
}

.btn--cancel {
  background-color: #e8ebf2 !important;
  color: #577077 !important;
}

.css-6prj3h-MuiStack-root .btn--cancel:hover {
  color: #e8ebf2;
  background-color: #577077;
}

.btn--cancel:hover {
  color: #e8ebf2 !important;
  background-color: #577077 !important;
}

.MuiStack-root #modal-modal-title {
  padding: 16px 0;
}
