/** @format */
.container .esgTargetsCardContainer {
  border-radius: 4px;
  background: #f6f7fb;
  width: 355px;
  padding: 16px;
}

.esgTargetsCardContainer .title {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.esgTargetsCardContainer .statistics {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.esgTargetsCardContainer .description {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  border-top: 1px solid rgba(130, 149, 156, 0.3);
}
