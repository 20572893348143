/** @format */

.cardContainer {
  height: 242px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid var(--Border, #e8ebf2);
  background: var(--white, #fff);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardContainer .cardTitle {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.cardContainer .cardPara {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: 16px;
}

.cardContainer .questionCount {
  color: #82959c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}
