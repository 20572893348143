/** @format */

.leadershipQuestionnaireContainer .instruction {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}
.leadershipQuestionnaireContainer .questionLabel {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.leadershipQuestionnaireContainer .tab5 {
  color: #82959c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.leadershipQuestionnaireContainer .questionText {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.leadershipQuestionnaireContainer .testing {
  display: flex;
  align-items: center;
}

.leadershipQuestionnaireContainer .list {
  margin-left: 60px;
  list-style: disc;
}
