/** @format */

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 64px;
  padding-right: 24px;
}

.header-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.header-title {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  padding: 0 24px;
}

.frame-icon,
.avatar-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.header-down-icon {
  width: 12px;
  height: 7.41px;
}
.left-header {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-left: 260px;
}
.header-user-details {
  display: flex;
  align-items: center;
  gap: 8px;
}
.header-user {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}
.right-header {
  display: flex;
  align-items: center;
  gap: 24px;
}

#demo-simple-select {
  width: 120px;
  font-size: 14px;
  background-color: #e7f5ec;
  color: #0f333d;
  padding: 8px 16px;
}

.MuiButtonBase-root
  .MuiMenuItem-root
  .MuiMenuItem-gutters
  .MuiMenuItem-root
  .MuiMenuItem-gutters
  .css-1qwd9uj-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 12px;
}
