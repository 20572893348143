/** @format */
div .title {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin: 24px;
}
div .pageHeading {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  padding: 16px;
  background-color: #f6f7fb;
}
tr .tableHead {
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.17px;
  text-transform: uppercase;
  color: #fff;
}
.tableSubTotal {
  border-bottom: 1px solid #e8ebf2;
  background: #e8ebf2;
}
.tableSubTotal td {
  color: #4f5367;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
}
.totalRow {
  border-bottom: 1px solid #e8ebf2;
  background: #4f5367;
}

.totalRow td {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
}
