/** @format */

.requestReport .pageHeading {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.25px;
}

.requestReport .textContent {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.requestReport .listHeading {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin-top: 24px;
}

.requestReport .listContainer {
  list-style: disc;
  margin-top: 10px;
  margin-bottom: 24px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.requestReport .listItem {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.requestReport .link {
  color: #ee7420;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  cursor: pointer;
}

.tableContainer .tableHeadRow {
  background: #0f333d;
}

.tableContainer .tableHeadCell {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}
.tableContainer .tableHeadCell:first-child {
  border-top-left-radius: 8px;
}
.tableContainer .tableHeadCell:last-child {
  border-top-right-radius: 8px;
}

.tableContainer .tableBodyCell {
  color: #4f5367;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.pagination li button {
  color: #577077;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}
.pagination ul {
  display: flex;

  align-items: center;
}
.pagination button[aria-current="true"] {
  color: #fff;
}
