/** @format */
.accordionContainer .accordionCard {
  /* position: relative; */
}

/* .accordionContainer .optionContainer {
  margin-bottom: 24px;
} */
.accordionContainer .radioBtn {
  display: flex;
}

.accordionContainer .questionLabel {
  color: #82959c;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}
