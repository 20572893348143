/** @format */

* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  font-family: "roboto" sans-serif;

  /* FOR SCROLL BAR */
  scrollbar-width: thin;
  /* scrollbar-height: thin; */
  scrollbar-color: #aaa #ddd;
}

/* FOR SCROLL BAR */
*::-ms-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #ddd;
}

*::-ms-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

body {
  background-color: #f6f8fc;
}
/* COMMON CSS APPLIABLE ON MULTIPLE PAGES*/
.page-headline {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.4%;
}

.tab--container .target-list-tab-width,
.target-list-tab-width .css-19kzrtu {
  padding: 24px 0 0 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: #ddd;
}

::-webkit-scrollbar-thumb {
  background-color: #0f333d;
  border-radius: 8px;
}
