/** @format */
.dashboardContainer {
  background-color: #f6f8fc;
}
.dashboardContainer .greet {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.4%;
}

.dashboardContainer .greetText {
  color: #577077;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}
/* .dashboardContainer .leftSideContainer {
  margin: 24px 0;
} */

.leftSideContainer table {
  margin-bottom: 24px;
}

.dashboardContainer .tableTitle {
  color: #4f5367;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 175%;
  letter-spacing: 0.15px;
}

.tableContainer .tableHeadRow {
  background: #0f333d;
}

.tableContainer .tableHeadCell {
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}
.tableContainer .tableHeadCell:first-child {
  border-top-left-radius: 8px;
}
.tableContainer .tableHeadCell:last-child {
  border-top-right-radius: 8px;
}

.tableContainer .tableBodyCell {
  color: #4f5367;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.dashboardContainer .tableBodyRow:last-child {
  background: #4f5367;
}

.dashboardContainer .tableBodyRow:last-child .tableBodyCell {
  font-feature-settings: 'clig' off, 'liga' off;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
}

.dashboardContainer .tableBodyRow:last-child .tableBodyCell:first-child {
  border-bottom-left-radius: 8px;
}

.dashboardContainer .tableBodyRow:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.tableBodyHeaderRow .tableBodyCell {
  border-bottom: 1px solid #4f5367;
  font-weight: 500;
}
