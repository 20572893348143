/** @format */

.energyPagination li button {
  color: #577077;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}
.energyPagination ul {
  display: flex;

  align-items: center;
}
.energyPagination button[aria-current="true"] {
  color: #fff;
}
