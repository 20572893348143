/** @format */

.esgLogoFooter {
  width: 177px;
  height: 50px;
  margin: auto;
}

.verticalDivider {
  border-left: 1px solid #fff;
  height: 82px;
}

.listitem {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.014px;
}

.footerHeadline {
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.4%; /* 26.68px */
}

.footerLink {
  color: #ee7420;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  cursor: pointer;
}

.cardLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
