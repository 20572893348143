/** @format */

.modalContainer .listContainer {
  list-style: disc;
  margin-top: 16px;
  margin-bottom: 24px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modalContainer .listItem {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.modalContainer .modalDescription {
  padding: 16px 24px;
}

.modalContainer .modaltext {
  margin: "16px 24px";
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.modalContainer .modalTitle {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.modalContainer .modalTitleCntainer {
  padding: 16px 24px;
  border-bottom: 1px solid #e8ebf2;
}

.modalContainer .confirmBtn {
  color: "#fff";
  font-family: "Roboto";
  font-size: "14px";
  font-style: "normal";
  font-weight: 500;
  line-height: "24px";
  letter-spacing: "0.4px";
  text-transform: "uppercase";
}

.modalContainer .cancelBtn {
  color: #577077;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
