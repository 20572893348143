/** @format */
.monthCard {
  background: #e8ebf2;
  border-radius: 8px;
  border: 1px solid #82959c;
  padding: 6px;
  min-width: 160px;
}

.cardValue {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  padding: 3px 6px;
}
