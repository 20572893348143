/** @format */

form .pageTitle {
  padding-bottom: 20px;
  border-bottom: 1px solid hsl(196, 12%, 56%, 0.3);
  display: flex;
  padding-right: 18px;
}

.pageTitle .title {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

form .pageSubHeading {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin: 16px 0;
}

form .backArrow {
  cursor: pointer;
  padding: 8px;
}

form .accordionTitle {
  color: #4f5367;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.accordion .summary {
  background-color: #f6f7fb;
}

form .tableContainer {
  border-radius: 8px;
}

form .tableHeadRow {
  border-radius: 8px;
  background: #0f333d;
}

.tableHeadRow th {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.tableBodyRow .tableBodyCell {
  color: #4f5367;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}
tr .headColl {
  color: #4f5367;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

tr .tableBodyCell {
  color: #4f5367;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.tableBodyCell .editBtn {
  cursor: pointer;
}

.editBtn path {
  color: #4f5367;
}

.tableBodyCell .calculateBtn {
  background-color: #e8ebf2;
  color: #4f5367;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  width: 100%;
}

.tableBodyCell .calculateBtn:hover {
  color: #e8ebf2;
  background-color: #4f5367;
}

.contaner:before {
  height: 0px !important;
}

.contaner {
  background-color: #fff;
  border-radius: 4px 4px 0 0;
}

.accordion {
  margin: 16px 0;
}

.tableBodyCell input:read-only {
  background-color: #eee;
}
.sk_dropdown_row {
  margin-top: 15px;
}

.scopeContainer {
  padding: 12px 6px 0 0;
  height: 77vh;
  overflow-y: auto;
}
