/** @format */

.chartContainer {
  width: 350px;
  height: 330px;
  position: relative;
  margin-top: 8px;
  border-radius: 10px;
  border: 0.5px solid #e8ebf2;
  background: #fff;
}

.chartContainer .btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.chart1 {
  position: relative;
  top: 30px;
}
.chart2 {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 270px;
}

.chart3 {
  position: absolute;
  top: 75px;
  left: 25px;
}
