/** @format */

.wiewActionPlanModal #modal-modal-title {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-family: "roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  padding: 16px 24px !important;
}
.modal-title {
  border-bottom: 1px solid #e8ebf2;
}
.action-plan-list-items {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  padding: 24px;
  margin-left: 30px;
}

.action-plan-list-item {
  margin: 8px 0;
  color: "#577077";
}

.MuiStack-root .css-3y1137-MuiStack-root {
  padding-bottom: 24px;
  border-bottom: 1px solid #e8ebf2;
}

.MuiStack-root .btn-view-action-plan,
.css-mrfq9r-MuiStack-root .btn-view-action-plan {
  min-width: 165px;
}
.css-79ws1d-MuiModal-root .css-f3yuuz {
  top: 30%;
}

/* TO HANDLE ERROR STATE OF CALENDAR FIELD */
.MuiFormControl-root
  .css-lnyhi4-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #33ae5e;
}
.MuiFormControl-root
  .css-nqkebe-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #33ae5e;
}
/* ======================================== */

#target-list-tab {
  width: 100%;
}

.target-list-tab-width span {
  display: block;
}

.target-list-tab-width .css-19kzrtu {
  padding: 24px 0;
}
