/** @format */

/* .layoutRight {
  margin-left: 260px;
  max-width: calc(100% - 308px);
} */

.dataVaultContainer .navLinks {
  /* color: #33ae5e; */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.dataVaultContainer li {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.dataVaultContainer .componentContainer {
  border-radius: 8px;
  background: #fff;
}

.assetContainer .pageHeading {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%; /* 32.016px */
}

.assetContainer .listLabel {
  color: #577077;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: 16px;
  display: inline-block;
}

.assetContainer .listItems {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
}

.assetContainer .listItem {
  color: #577077;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}
.assetContainer .importContainer {
  margin: 24px 0;
}
.importContainer .import {
  color: var(--Dark, #0f333d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%;
}

.importContainer .importBox {
  border-radius: 4px;
  border: 1px dashed var(--Border, #e8ebf2);
  background: var(--Background-light-grey, #f6f7fb);
  padding: 32px 16px;
  margin-top: 16px;
}

.importContainer .clickToUpload {
  color: #33ae5e;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
  text-decoration: underline;
  cursor: pointer;
}
.importContainer .drag {
  color: #4f5367;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
}

.importContainer .fileType {
  color: #577077;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.dataVaultContainer .inputLabel {
  line-height: 0.85;
}
