/** @format */
.barChartContainer {
  background-color: #f6f7fb;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 0;
  padding-bottom: 25%;
  margin-bottom: 25px;
}

.horizontalBarChartContainer {
  box-sizing: border-box;
  width: 100%;
  height: 0;
  padding-bottom: 25%;
  margin-bottom: 25px;
}

.horizontalBarChartContainer .titleContainer,
.barChartContainer .titleContainer,
.doughnutContainer .titleContainer {
  border-bottom: 1px solid #e8ebf2;
}

.barChart {
  /* height: 283px; */
  padding: 24px;
}

.titleContainer .chartTitle {
  color: #4f5367;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 45px;
}

.doughnutChart {
  /* height: 276px; */
  padding: 40px;
}
.doughnutContainer {
  border-radius: 6px;
  background-color: #f6f7fb;
  /* width: 500px; */
  /* max-height: 388.5px; */
  box-sizing: border-box;
  /* padding: 20px; */
}

.doughnutContainer .colorBox1,
.doughnutContainer .colorBox2,
.doughnutContainer .colorBox3,
.doughnutContainer .colorBox4 {
  width: 21px;
  height: 8px;
}
.doughnutContainer .colorBox1 {
  background: #a155b9;
}
.doughnutContainer .colorBox2 {
  background: #f765a3;
}
.doughnutContainer .colorBox3 {
  background: #165baa;
}
.doughnutContainer .colorBox4 {
  background: #16bfd6;
}

.doughnutContainer .legend {
  color: #4f5367;
  font-family: roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.doughnutContainer .listItems {
  list-style: none;
  /* width: 400px; */
  height: 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
}
.doughnutContainer .listItem {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 200px;
}

.noData {
  padding: 25px 50px;
  color: #4f5367;
  font-family: roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
