/** @format */

.pageTitle {
  padding-bottom: 32px;
  border-bottom: 1px solid hsl(196, 12%, 56%, 0.3);
}
.title {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.backArrow {
  cursor: pointer;
}

.instruction {
  color: #4f5367;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}
