/** @format */

.container .pageTitle {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%;
}

.tenantContainer .pageTitle {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.container .description {
  color: var(--Grey-Text, #577077);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.container .tableHeadCell {
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  white-space: 'nowrap';
  text-overflow: 'ellipsis';
}

.container .tableBodyCell {
  color: #577077;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.assetContainer .pageTitle {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%;
}

.assetContainer .details {
  color: #577077;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
}

.tooltipTitle {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}
.tooltipExplaination {
  color: #4f5367;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.scrollable-container {
  overflow-x: auto;
  white-space: nowrap;
}

.container .leftTable {
  border-top-right-radius: 0;
  overflow-x: hidden;
}

.container .centerTable {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-left: 1px solid #e8ebf2;
  border-right: 1px solid #e8ebf2;
}

.container .rightTable {
  border-top-left-radius: 0;
  overflow-x: hidden;
}

.container .tableTypography {
  color: #577077;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.container .tableHeadCells {
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container .dropdown {
  color: #577077;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.container .dropdownContainer fieldset {
  border: none;
}

.container .dropdownContainer {
  background-color: #fff;
  min-width: 120px;
  padding: 0;
}

.container .chartTitle {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%;
}

.chartContainer {
  border: 1px solid #e8ebf2;
  background: #fff;
}
/* .tableContainer .tableHead {
} */
.tableContainer .tableHeadRow {
  border-radius: 8px;
  background: #e7f5ec;
}
.tableContainer .tableHeadCell {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
  text-transform: capitalize;
}
.tableContainer .tableBody {
  border-bottom: 1px solid var(--Border, #e8ebf2);
}
.tableContainer .tableBodyRow {
  border-bottom: 1px solid var(--Border, #e8ebf2);
}
.tableContainer .tableBodyCell {
  color: #577077;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.tenantContainer .tableTitle {
  color: var(--Dark, #0f333d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: 0.15px;
}

.tenantContainer .docTitle {
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
}

.tenantContainer .docTable {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
}
