/** @format */

.pageTitle {
  padding-bottom: 32px;
  border-bottom: 1px solid hsl(196, 12%, 56%, 0.3);
}

.title {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.backArrow {
  cursor: pointer;
}

form .enterMonthly {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%;
}

tr .tableHead {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.17px;
  text-transform: uppercase;
}

tr .tableCell {
  padding: 8px;
}

.tableCell input {
  color: #4f5367;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 8px 12px;
}

.inputFieldContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 16px;
  margin: 32px 0;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 20px;
  column-gap: 16px;
  padding: 20px;
  background: #f6f8fc;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px #d9dbe4;
}

@media only screen and (max-width: 1200px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 900px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 750px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
