/** @format */

.sdgQuestionnaireContainer .title {
  color: var(--Dark, #0f333d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%; /* 32.016px */
}

.sdgQuestionnaireContainer .brief {
  color: var(--Grey-Text, #577077);
  font-feature-settings: 'clig' off, 'liga' off;

  /* typography/body1 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
}

.sdgQuestionnaireContainer .questionLabel {
  color: #4f5367;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.15px;
}

.sdgQuestionnaireContainer .questionCount {
  color: #0f333d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.sdgQuestionnaireContainer .commentContainer {
  display: grid;
  grid-template-columns: 11fr 1.3fr;
}

.sdgQuestionnaireContainer .isRelevant {
  color: var(--Grey-Text-2, #82959c);
  font-feature-settings: 'liga' off, 'clig' off;

  /* typography/body1 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
}
