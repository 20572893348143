/** @format */

.leadershipContainer {
  padding: 24px;
  padding-bottom: 0;
  border-radius: 8px;
  background: #fff;
}
.leadershipContainer .pageHeading {
  color: #0f333d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.4%;
}

.leadershipContainer .explaination {
  color: #577077;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.leadershipContainer .cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
